// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData, useMatches } from "@remix-run/react";
import { json } from "@remix-run/node";
import { useEffect, useState } from "react";
import { getUserId } from "~/utils/auth.server";
import Layout from "~/components/Layout";
import styles from "~/styles/app.css";
import { NextUIProvider } from "@nextui-org/react";
import { ThemeProvider as NextThemesProvider } from "next-themes";
export const links = () => [{
  rel: "stylesheet",
  href: styles
}];
export const loader = async ({
  request
}) => {
  const userId = await getUserId(request);
  return json({
    isLoggedIn: !!userId
  });
};
function OutletWithConditionalLayout({
  isLoggedIn,
  toggleTheme,
  theme
}) {
  _s();
  const matches = useMatches();
  const hideLayout = matches.some(match => match.handle?.hideLayout);
  if (hideLayout) {
    return <Outlet />;
  }
  return <Layout isLoggedIn={isLoggedIn} toggleTheme={toggleTheme} theme={theme}>
            <Outlet />
        </Layout>;
}
_s(OutletWithConditionalLayout, "9HQn1rkLPttBP+QSK6GDQicXTV4=", false, function () {
  return [useMatches];
});
_c = OutletWithConditionalLayout;
export default function App() {
  _s2();
  const {
    isLoggedIn
  } = useLoaderData();
  const [theme, setTheme] = useState("light");
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    setTheme(savedTheme);
    document.documentElement.classList.toggle("dark", savedTheme === "dark");
  }, []);
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.documentElement.classList.toggle("dark", newTheme === "dark");
  };
  return <html lang="de" className={theme}>
            <head>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width,initial-scale=1" />
                <Meta />
                <Links />
            </head>
            <body>
                <NextThemesProvider attribute="class" defaultTheme="system">
                    <NextUIProvider>
                        <OutletWithConditionalLayout isLoggedIn={isLoggedIn} toggleTheme={toggleTheme} theme={theme} />
                    </NextUIProvider>
                </NextThemesProvider>
                <ScrollRestoration />
                <Scripts />
                <LiveReload />
            </body>
        </html>;
}
_s2(App, "u9kNy6u9nmGcMQdWMCgX88Pyzm8=", false, function () {
  return [useLoaderData];
});
_c2 = App;
var _c, _c2;
$RefreshReg$(_c, "OutletWithConditionalLayout");
$RefreshReg$(_c2, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;