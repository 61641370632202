// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Layout.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Layout.tsx");
  import.meta.hot.lastModified = "1728511072112.5212";
}
// REMIX HMR END

import { Card } from "@nextui-org/react";
import NavBar from "./NavBar";
export default function Layout({
  children,
  isLoggedIn,
  toggleTheme,
  theme
}) {
  return <div className="min-h-screen flex flex-col bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-900 dark:to-gray-800">
            <NavBar isLoggedIn={isLoggedIn} toggleTheme={toggleTheme} theme={theme} />
            <main className="flex-grow py-8 px-4 sm:px-6 lg:px-8">
                <Card className="max-w-7xl mx-auto p-6 shadow-lg bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm">
                    {children}
                </Card>
            </main>
            <footer className="py-4 text-center text-sm text-gray-600 dark:text-gray-400 bg-white/30 dark:bg-gray-800/30 backdrop-blur-sm">
                &copy; 2024 HR-Verwaltung. Alle Rechte vorbehalten.
            </footer>
        </div>;
}
_c = Layout;
var _c;
$RefreshReg$(_c, "Layout");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;