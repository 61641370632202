// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/NavBar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/NavBar.tsx");
  import.meta.hot.lastModified = "1728511072112.5212";
}
// REMIX HMR END

import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button, Switch } from "@nextui-org/react";
import { Form } from "@remix-run/react";
import { SunIcon, MoonIcon } from "@nextui-org/shared-icons";
import { useState } from 'react';
export default function NavBar({
  isLoggedIn,
  toggleTheme,
  theme
}) {
  _s();
  const [isEmployeeMenuOpen, setIsEmployeeMenuOpen] = useState(false);
  return <Navbar isBordered className="bg-white/70 dark:bg-gray-800/70 backdrop-blur-sm">
            <NavbarBrand>
                <Link href="/" color="foreground">
                    <p className="font-bold text-inherit">Verwaltung</p>
                </Link>
            </NavbarBrand>
            <NavbarContent className="hidden sm:flex gap-4" justify="center">
                {isLoggedIn && <>
                        <NavbarItem>
                            <Link color="foreground" href="/dashboard">
                                Dashboard
                            </Link>
                        </NavbarItem>
                        <NavbarItem>
                            <Link color="foreground" href="/users">
                                Benutzer
                            </Link>
                        </NavbarItem>
                        <NavbarItem>
                            <div className="relative">
                                <button onClick={() => setIsEmployeeMenuOpen(!isEmployeeMenuOpen)} className="text-foreground">
                                    Mitarbeiter ▼
                                </button>
                                {isEmployeeMenuOpen && <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-700 rounded-md shadow-lg py-1 z-10">
                                        <Link href="/employees" className="block px-4 py-2 text-sm">
                                            Übersicht
                                        </Link>
                                        <Link href="/employees/new" className="block px-4 py-2 text-sm">
                                            Neuer Mitarbeiter
                                        </Link>
                                        <Link href="/employees/overtime" className="block px-4 py-2 text-sm">
                                            Überstundenübersicht
                                        </Link>
                                    </div>}
                            </div>
                        </NavbarItem>
                        <NavbarItem>
                            <Link color="foreground" href="/shifts">
                                Schichtplanung
                            </Link>
                        </NavbarItem>
                        <NavbarItem>
                            <Link color="foreground" href="/vacations">
                                Abwesenheiten
                            </Link>
                        </NavbarItem>
                    </>}
            </NavbarContent>
            <NavbarContent justify="end">
                {isLoggedIn ? <NavbarItem>
                        <Form method="post" action="/logout">
                            <Button type="submit" color="danger" variant="flat">
                                Abmelden
                            </Button>
                        </Form>
                    </NavbarItem> : <>
                        <NavbarItem>
                            <Link href="/login">Anmelden</Link>
                        </NavbarItem>
                        <NavbarItem>
                            <Button as={Link} color="primary" href="/register" variant="flat">
                                Registrieren
                            </Button>
                        </NavbarItem>
                    </>}
                <NavbarItem>
                    <Switch defaultSelected={theme === 'dark'} size="lg" color="secondary" startContent={<SunIcon />} endContent={<MoonIcon />} onChange={toggleTheme} />
                </NavbarItem>
            </NavbarContent>
        </Navbar>;
}
_s(NavBar, "K5njmg6SvM2ym1ixMZG+EEhkeV4=");
_c = NavBar;
var _c;
$RefreshReg$(_c, "NavBar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;